<template>
  <AuthentificationLayout>
    <template #background-image>
      <img
        :data-src="require('@/assets/images/planet-ring.svg')"
        alt="planet"
      >
    </template>
    <template #text-page>
      <p class="geminis text-7xl lg:text-8xl xl:text-9xl text-shadow text-right">
        {{ $t('common.welcomeBack').toUpperCase() }}
      </p>
    </template>
    <template #form>
      <p class="geminis text-space-green text-4xl text-center pb-3">
        {{ $t('common.signIn').toUpperCase() }}
      </p>
      <div class="flex flex-col space-y-6">
        <LoginForm />
        <div
          id="ConnexionButton"
          class="p-6 rounded-3xl bg-white flex flex-col space-y-2"
        >
          <span
            :class="(!OAuthError ? 'hidden' : 'error-box text-space-darker-red w-full mb-3')"
          >
            {{ OAuthError }}
          </span>
          <PrimaryButton
            :bg-color="'bg-space-dark-blue'"
            :text="$t('signInForm.withGoogle')"
            @click="() => oauthLogin('google')"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 30 30.581"
              >
                <path
                  id="Icon_ionic-logo-google"
                  data-name="Icon ionic-logo-google"
                  d="M33.48,16.316l-.154-.654H19.1v6.022h8.5c-.882,4.191-4.978,6.4-8.324,6.4a9.954,9.954,0,0,1-6.7-2.669,9.563,9.563,0,0,1-2.853-6.75,9.863,9.863,0,0,1,2.8-6.743,9.529,9.529,0,0,1,6.654-2.6,8.68,8.68,0,0,1,5.662,2.206l4.279-4.257A15.141,15.141,0,0,0,19.039,3.382h0A15.584,15.584,0,0,0,8.009,7.867a15.521,15.521,0,0,0-4.353,10.8A15.365,15.365,0,0,0,7.84,29.294a15.961,15.961,0,0,0,11.507,4.669,14.2,14.2,0,0,0,10.338-4.346,15.264,15.264,0,0,0,3.971-10.574A17.606,17.606,0,0,0,33.48,16.316Z"
                  transform="translate(-3.656 -3.382)"
                  fill="currentColor"
                />
              </svg>
            </template>
          </PrimaryButton>
          <PrimaryButton
            :bg-color="'bg-space-dark-blue'"
            :text="$t('signInForm.withGithub')"
            @click="() => oauthLogin('github')"
          >
            <template #icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 30 30"
              >
                <path
                  id="Icon_awesome-github"
                  data-name="Icon awesome-github"
                  d="M10.034,24.115c0,.121-.139.218-.315.218-.2.018-.339-.079-.339-.218,0-.121.139-.218.315-.218C9.877,23.879,10.034,23.976,10.034,24.115Zm-1.881-.272c-.042.121.079.26.26.3a.284.284,0,0,0,.375-.121c.036-.121-.079-.26-.26-.315A.31.31,0,0,0,8.153,23.843Zm2.673-.1c-.175.042-.3.157-.278.3.018.121.175.2.357.157s.3-.157.278-.278S11,23.722,10.827,23.74ZM14.806.563A14.5,14.5,0,0,0,0,15.321,15.164,15.164,0,0,0,10.252,29.788c.774.139,1.046-.339,1.046-.732,0-.375-.018-2.444-.018-3.714,0,0-4.234.907-5.123-1.8,0,0-.69-1.76-1.681-2.214,0,0-1.385-.95.1-.931a3.193,3.193,0,0,1,2.335,1.56,3.2,3.2,0,0,0,4.409,1.264,3.362,3.362,0,0,1,.968-2.038C8.9,20.806,5.492,20.317,5.492,14.5a4.584,4.584,0,0,1,1.427-3.563,5.715,5.715,0,0,1,.157-4.107c1.264-.393,4.173,1.633,4.173,1.633a14.289,14.289,0,0,1,7.6,0s2.909-2.032,4.173-1.633a5.712,5.712,0,0,1,.157,4.107,4.7,4.7,0,0,1,1.56,3.563c0,5.837-3.562,6.3-6.944,6.683a3.577,3.577,0,0,1,1.028,2.806c0,2.038-.018,4.56-.018,5.056,0,.393.278.871,1.046.732A15.024,15.024,0,0,0,30,15.321C30,6.931,23.2.563,14.806.563ZM5.879,21.423c-.079.06-.06.2.042.315.1.1.236.139.315.06.079-.06.06-.2-.042-.315C6.1,21.387,5.958,21.345,5.879,21.423Zm-.653-.49c-.042.079.018.175.139.236a.18.18,0,0,0,.26-.042c.042-.079-.018-.175-.139-.236C5.365,20.855,5.268,20.873,5.226,20.933Zm1.96,2.153c-.1.079-.06.26.079.375.139.139.315.157.393.06.079-.079.042-.26-.079-.375C7.446,23.008,7.264,22.99,7.185,23.087ZM6.5,22.2c-.1.06-.1.218,0,.357s.26.2.339.139a.276.276,0,0,0,0-.375C6.75,22.179,6.593,22.119,6.5,22.2Z"
                  fill="currentColor"
                />
              </svg>
            </template>
          </PrimaryButton>
        </div>
      </div>
    </template>
  </AuthentificationLayout>
</template>

<script>
import AuthentificationLayout from "../../layouts/partials/authentification-layout/AuthentificationLayout";
import LoginForm from "../../components/login/LoginForm";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import Router, {ROUTE_HOME} from "@/router";
import {redirectToGoogleLogin} from "@/utils/redirectToGoogleLogin";
import {LOGIN_ACTION} from "@/store/modules/userModule/actions";
import {redirectToGithubLogin} from "@/utils/redirectToGithubLogin";

export default {
  name : "Login",
  components: {
    PrimaryButton,
    LoginForm,
    AuthentificationLayout,
  },
  data(){
    return {
      OAuthError: null
    };
  },
  mounted() {
    //check if query param includes token
    if (this.$route.query.user) {
      //if yes, call login with token
      try {
        const data = JSON.parse(this.$route.query.user);
        if (data.id){
          this.$store.dispatch(LOGIN_ACTION, {
            is_guest: false,
            ...data
          });
          Router.push(ROUTE_HOME.path);
        }

      } catch (e) {
        this.$toast.error('Erreur lors de la connexion via Google, réessayes plus tard');
      }
    }

    if (this.$route.query.OAuthError){
      this.OAuthError = this.$t(`signInForm.errors.oauth.${this.$route.query.OAuthError}`);
    }
  },
  methods: {
    oauthLogin(type) {
      type === 'google' ? redirectToGoogleLogin() : redirectToGithubLogin();
    },
  },
};
</script>
