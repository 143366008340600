<template>
  <div
    id="LoginForm"
    class="bg-white text-black p-6 rounded-[3em] flex flex-col items-center"
  >
    <Transition
      name="fade"
      mode="out-in"
    >
      <span
        :class="(!errors.formError ? 'hidden' : 'error-box text-space-darker-red w-full mb-3')"
      >
        {{ errors.formError }}
      </span>
    </Transition>
    <div class="flex flex-col space-y-3 w-full pb-8 pt-4">
      <TextFormInput
        placeholder="john.doe@gmail.com"
        :type="'email'"
        :value="formData.email"
        field="email"
        :errors="errors"
        :label="$t('registrationForm.email')"
        :wrapper-classname="'bg-space-dark-blue'"
        @input="formData.email = $event; errors.email = false"
      />
      <TextFormInput
        :placeholder="$t('registrationForm.password')"
        :type="'password'"
        :value="formData.password"
        field="password"
        :errors="errors"
        :label="$t('registrationForm.password')"
        :wrapper-classname="'bg-space-dark-blue'"
        @input="formData.password = $event; errors.password = false"
      />
      <router-link :to="ROUTE_RESET_PASSWORD.path" class="text-xs font-bold text-right text-space-darker-blue">
        {{ $t('signInForm.forgotPassword') }}
      </router-link>
    </div>
    <PrimaryButton
      :bg-color="'bg-space-dark-blue'"
      :text="$t('common.signIn')"
      @click="onLogin"
      :classes="'w-2/3 text-white'"
    />
    <p class="text-xs pt-5 text-center">
      {{ $t('signInForm.notSignedUp') }}
      <router-link
        :to="{name: 'register'}"
        class="font-bold no-underline cursor-pointer"
      >
        {{ $t('signInForm.signUp') }}
      </router-link>
    </p>
  </div>
</template>

<script>
import UserService from "@/services/userService";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import TextFormInput from "@/components/inputs/TextFormInput";
import FormService from "@/services/formService";
import {ROUTE_RESET_PASSWORD} from "@/router";

export default {
  name: "LoginForm",
  computed: {
    ROUTE_RESET_PASSWORD() {
      return ROUTE_RESET_PASSWORD;
    }
  },
  components: {TextFormInput, PrimaryButton},
  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
      errors: {}
    };
  },
  methods: {
    async onLogin() {
      const formErrors = {
        email: FormService.checkNotEmpty(this.formData.email),
        password: FormService.checkNotEmpty(this.formData.password),
      };

      if (FormService.hasErrors(formErrors)) {
        this.errors = formErrors;
        return;
      }

      const error = await UserService.signIn(this.formData, this.$route);
      if (error) {
        this.errors = {
          formError: error
        };
      }
    }
  }
};
</script>

<style scoped>
input, button {
  box-shadow: 0 0 20px rgba(77, 42, 156, 0.43);
}
</style>
