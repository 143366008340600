/**
 * Redirects user to SpaceCode Github OAuth page
 * @returns {Promise<void>}
 */
export const redirectToGithubLogin = async () => {
  const rootUrl = `https://github.com/login/oauth/authorize?`;

  const options = {
    redirect_uri: `${process.env.VUE_APP_API_URL}/auth/github/redirect`,
    client_id: `${process.env.VUE_APP_GITHUB_CLIENT_ID}`,
    scope:'user',
    type: 'user_agent'
  };

  const qs = new URLSearchParams(options);

  window.location.href = `${rootUrl}?${qs.toString()}`;
};
