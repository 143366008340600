/**
 * Redirects user to SpaceCode Google OAuth page
 * @param from
 * @returns {Promise<void>}
 */
export const redirectToGoogleLogin = async (from) => {
  const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

  const options = {
    redirect_uri: `${process.env.VUE_APP_API_URL}/auth/google/redirect`,
    client_id: `${process.env.VUE_APP_GOOGLE_CLIENT_ID}`,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    scope: [
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
    ].join(' '),
    state: from,
  };

  const qs = new URLSearchParams(options);

  window.location.href = `${rootUrl}?${qs.toString()}`;
};
